





































































import {
  Component, Vue, Watch, Prop
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/api';
import { CircuitsAction, CircuitsActionType, CircuitsState } from '@/store/modules/circuits/types';
import { AppState } from '@/store/modules/app/types';
import { sort } from '@/utils/sort';
import { focusOnNewItemNameInput } from '@/utils/focus_on_input';
import { nameFilter } from '@/utils/name_filter';
import ColorPicker from './ColorPicker/ColorPicker.vue';

const circuits = namespace('circuits');
const app = namespace('app');

@Component({
  components: {
    ColorPicker,
  },
})
export default class EditableCircuitsList extends Vue {
  modifiedCircuit: Models.Circuits.Model | null = null;

  colorPicker = false;

  deleteData: Models.Circuits.Model | null = null;

  dialog = false;

  colorPickerPosition = { left: '0px', top: '0px' };

  key = 1;

  colorDuringModify: boolean = false;

  showNameTooltip: boolean = false;

  itemName = '';

  @Prop(Boolean) readonly permissionCheckWrite!: boolean;

  @Prop(Boolean) readonly permissionCheckDelete!: boolean;

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @circuits.State
  circuits!: CircuitsState['circuits'];

  @circuits.State
  selectedCircuitID!: CircuitsState['selectedCircuitID'];

  @circuits.Action(CircuitsAction.SetSelectedCircuitID)
  setSelectedCircuitID!: CircuitsActionType['SET_SELECTED_CIRCUIT_ID']

  @circuits.Action(CircuitsAction.SetSelectedCircuitUuid)
  setSelectedCircuitUuid!: CircuitsActionType['SET_SELECTED_CIRCUIT_UUID']

  @circuits.Action(CircuitsAction.AddCircuitPlaceholder)
  addCircuitPlaceholder!: CircuitsActionType['ADD_CIRCUIT_PLACEHOLDER']

  @circuits.Action(CircuitsAction.RemoveCircuitPlaceholder)
  removePlaceholderCircuit!: CircuitsActionType['REMOVE_CIRCUIT_PLACEHOLDER']

  @circuits.Action(CircuitsAction.DeleteCircuit)
  deleteCircuit!: CircuitsActionType[CircuitsAction.DeleteCircuit]

  @circuits.Action(CircuitsAction.CreateCircuit)
  createCircuit!: CircuitsActionType[CircuitsAction.CreateCircuit]

  @circuits.Action(CircuitsAction.UpdateCircuit)
  updateCircuit!: CircuitsActionType[CircuitsAction.UpdateCircuit]

  get isPlaceholderCircuitInStore() {
    return this.circuits.some((c) => !c.id);
  }

  get circuitColorsArray() {
    const circuitColors: string[] = [];
    const isBeingCreatedCircuit = !this.modifiedCircuit?.uuid;
    this.circuits.forEach((c: Models.Circuits.Model) => {
      circuitColors.push(c.color);
    });
    if (isBeingCreatedCircuit) circuitColors.splice(0, 1);
    return circuitColors;
  }

  get circuitNamesArray() {
    const circuitNames: string[] = [];
    this.circuits.forEach((c: Models.Circuits.Model) => {
      circuitNames.push(c.name);
    });
    return circuitNames.filter((c) => c !== this.itemName);
  }

  @Watch('modifiedCircuit')
  onModifiedCircuitChange() {
    this.setSelectedCircuitID(null);
    this.setSelectedCircuitUuid(null);
  }

  modifyCircuitColor(e) {
    if (this.modifiedCircuit) this.modifiedCircuit.color = e;
    this.colorDuringModify = false;
    this.closeColorPicker();
  }

  closeColorPicker() {
    this.colorPicker = false;
  }

  filteredCircuits(searchPhrase: string) {
    const sortedCircuits = [...this.circuits].sort((a, b) => sort(a.name, b?.name));
    return sortedCircuits.filter((c) => nameFilter(c.name, searchPhrase));
  }

  isModifiedCircuit(circuitId: number | undefined) {
    return this.modifiedCircuit && this.modifiedCircuit.id === circuitId;
  }

  isSelectedCircuit(circuitId: number) {
    return this.selectedCircuitID === circuitId;
  }

  eventHandlers(circuit) {
    return {
      select: () => this.selectItem(circuit),
      edit: () => this.editItem(circuit),
      discard: this.discard,
      save: this.save,
      modalAction: () => {
        this.dialog = true;
        this.deleteData = circuit;
      }
    };
  }

  selectItem(circuit: Models.Circuits.Model): void {
    if (this.userActionStatus.status === 'pending'
      || this.isPlaceholderCircuitInStore
      || this.modifiedCircuit) return;
    if (this.isSelectedCircuit(circuit.id!)) {
      this.setSelectedCircuitID(null);
      this.setSelectedCircuitUuid(null);
    } else {
      this.setSelectedCircuitID(circuit.id!);
      this.setSelectedCircuitUuid(circuit.uuid!);
    }
  }

  editItem(circuit: Models.Circuits.Model): void {
    this.modifiedCircuit = { ...circuit };
    this.itemName = this.modifiedCircuit.name;
    focusOnNewItemNameInput();
  }

  deleteItem(circuit: Models.Circuits.Model): void {
    this.deleteCircuit(circuit.uuid as string);
  }

  closeModal() {
    this.dialog = false;
    this.deleteData = null;
  }

  deleteModal() {
    // eslint-disable-next-line
    this.deleteData && (this.deleteItem(this.deleteData));
    this.dialog = false;
    this.deleteData = null;
  }

  save(): void {
    if (!this.modifiedCircuit) {
      return;
    }
    this.colorPicker = false;
    if (this.colorDuringModify) return;
    if (!this.modifiedCircuit.name || this.circuitNamesArray.includes(this.modifiedCircuit.name)) {
      focusOnNewItemNameInput();
      this.showNameTooltip = true;
      return;
    }
    this.showNameTooltip = false;
    if (!this.modifiedCircuit.id) {
      if (this.circuitColorsArray.includes(this.modifiedCircuit.color)) {
        this.togglePicker(undefined);
        return;
      }
      this.createCircuit(this.modifiedCircuit)
        .catch(() => {
          this.removePlaceholderCircuit();
        })
        .finally(() => {
          this.modifiedCircuit = null;
        });
    } else {
      this.updateCircuit(this.modifiedCircuit)
        .catch(() => {
          this.key += 1;
        })
        .finally(() => {
          this.modifiedCircuit = null;
          this.colorDuringModify = false;
        });
    }
    this.removePlaceholderCircuit();
  }

  discard(): void {
    if (!this.modifiedCircuit) {
      return;
    }
    if (!this.modifiedCircuit.id) {
      this.removePlaceholderCircuit();
    }
    this.colorPicker = false;
    this.showNameTooltip = false;
    this.modifiedCircuit = null;
    this.key += 1;
  }

  addCircuit(): void {
    if (!this.circuits.some((c) => !c.id)) {
      this.setSelectedCircuitID(null);
      this.setSelectedCircuitUuid(null);
      this.addCircuitPlaceholder();
      this.$nextTick(() => {
        this.modifiedCircuit = JSON.parse(JSON.stringify(this.circuits.find((c) => !c.id)));
      });
    }
    focusOnNewItemNameInput();
  }

  togglePicker(circuitId: number | undefined) {
    if (!(this.isModifiedCircuit(circuitId))) return;
    this.updateColorPickerPosition();
    this.colorPicker = !this.colorPicker;
    this.colorDuringModify = !this.colorDuringModify;
  }

  circuitColor(c: Models.Circuits.Model) {
    if (this.modifiedCircuit && this.modifiedCircuit.id === c.id) {
      return this.modifiedCircuit.color;
    }
    return c.color;
  }

  updateColorPickerPosition() {
    if (this.modifiedCircuit) {
      const colorPickerActivator = this.$refs[
        `color-picker-activator-${this.modifiedCircuit.id}`
      ];
      const { top, left } = colorPickerActivator![0].getBoundingClientRect();
      this.colorPickerPosition = {
        top: `${top - 122}px`,
        left: `${left + 20}px`,
      };
    }
  }

  mounted() {
    if (!this.selectedCircuitID && this.circuits.length) {
      const { id, uuid } = this.circuits.sort((a, b) => sort(a.name, b.name))[0];
      this.setSelectedCircuitID(id!);
      this.setSelectedCircuitUuid(uuid!);
    }
    const placeholder = this.circuits.find((c) => !c.id);
    if (placeholder) {
      this.modifiedCircuit = JSON.parse(JSON.stringify(placeholder));
    }
    window.addEventListener('resize', () => {
      if (this.colorPicker) {
        this.updateColorPickerPosition();
      }
    });
  }

  destroyed() {
    this.setSelectedCircuitID(null);
    this.setSelectedCircuitUuid(null);
    this.removePlaceholderCircuit();
    window.removeEventListener('resize', () => {
      if (this.colorPicker) {
        this.updateColorPickerPosition();
      }
    });
  }
}
