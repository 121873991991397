


































import {
  Component, Vue, Prop
} from 'vue-property-decorator';

@Component
export default class ColorPicker extends Vue {
  @Prop({ required: true, type: String }) readonly hex!: string

  @Prop({ required: true, type: Boolean }) readonly isNewCircuit!: boolean

  @Prop({ required: false, type: Array }) readonly forbiddenColors!: string[]

  savedHex: string = this.hex;

  newHex: string = this.hex;

  colorSimilar: boolean | null = null;

  colorTaken: boolean | null = null;

  checkIfColorForbiddenOrSimilar(hex: string) {
    if (this.checkIfColorForbidden(hex)) return;
    this.checkIfColorSimilar(hex);
  }

  checkIfColorForbidden(hex: string) {
    if (this.forbiddenColors.includes(hex) && (hex !== this.savedHex || this.isNewCircuit)) {
      this.colorTaken = true;
      return true;
    }
    this.colorTaken = false;
    return false;
  }

  checkIfColorSimilar(hex: string) {
    const acceptedSimilarity = 0.85;
    this.colorSimilar = !this.forbiddenColors.every((fC) => {
      if (fC === this.savedHex) return true;
      return this.hexColorSimilarity(hex, fC) < acceptedSimilarity;
    });
    this.colorTaken = false;
  }

  addMissingColorChangeEventListeners() {
    const input: HTMLInputElement | null = document.querySelector('[maxlength="7"]');
    const colorSlider = document.querySelector('.v-color-picker__preview.v-color-picker__preview--hide-alpha');
    
    if (input) {
      input.addEventListener('input', () => {
        this.newHex = input ? input.value : this.savedHex;
        this.checkIfColorForbiddenOrSimilar(this.newHex);
      });
      if (colorSlider) {
        colorSlider.addEventListener('click', () => {
          this.newHex = input ? input.value : this.savedHex;
          this.checkIfColorForbiddenOrSimilar(this.newHex);
        });
        colorSlider.addEventListener('keyup', (e: any) => {
          const importantCodes = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'];
          if (importantCodes.includes(e.code)) {
            this.newHex = input.value;
            this.checkIfColorForbiddenOrSimilar(this.newHex);
          }
        });
      }
    }
  }

  updateColor(value) {
    this.checkIfColorForbiddenOrSimilar(value);
  }

  hexColorSimilarity(hexColor1: string, hexColor2: string) {
    const red1Int = parseInt(hexColor1.substring(1, 3), 16);
    const green1Int = parseInt(hexColor1.substring(3, 5), 16);
    const blue1Int = parseInt(hexColor1.substring(5, 7), 16);
    
    const red2Int = parseInt(hexColor2.substring(1, 3), 16);
    const green2Int = parseInt(hexColor2.substring(3, 5), 16);
    const blue2Int = parseInt(hexColor2.substring(5, 7), 16);

    let redDiff = 255 - Math.abs(red1Int - red2Int);
    let greenDiff = 255 - Math.abs(green1Int - green2Int);
    let blueDiff = 255 - Math.abs(blue1Int - blue2Int);
    // set differences between 0 and 1
    redDiff /= 255;
    greenDiff /= 255;
    blueDiff /= 255;
    // the closer to 1 the more similar colors are
    return (redDiff + greenDiff + blueDiff) / 3;
  }

  emitColorChange() {
    this.$emit('update-color', this.newHex);
  }

  discardChange(e) {
    if (e.key && e.key !== 'Escape') return;
    this.newHex = this.savedHex;
    document.removeEventListener('keyup', this.discardChange);
    this.emitColorChange();
  }

  created() {
    document.addEventListener('keyup', this.discardChange);
  }

  mounted() {
    this.addMissingColorChangeEventListeners();
    this.checkIfColorForbiddenOrSimilar(this.savedHex);
  }
}
