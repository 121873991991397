var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"color-picker-card pr-3 py-2 pl-5"},[_c('v-color-picker',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.discardChange),expression:"discardChange"}],class:{ 'color-taken': _vm.colorTaken },attrs:{"hide-canvas":"","mode":"hexa","value":_vm.hex},on:{"input":_vm.updateColor}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.colorTaken || _vm.colorSimilar),expression:"colorTaken || colorSimilar"}],staticClass:"color-picker-card__color-message"},[(_vm.colorTaken)?_c('BaseTooltip',{attrs:{"content":_vm._f("capitalize")(_vm.$t('tooltips.colorTakenChooseAgain')),"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.colorTaken),expression:"colorTaken"}],attrs:{"size":"18px","color":"rgba(255, 82, 82, 0.71)"}},on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2759623444)}):(_vm.colorSimilar)?_c('BaseTooltip',{attrs:{"content":_vm._f("capitalize")(_vm.$t('tooltips.colorSimilarSuggestedChange')),"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.colorSimilar),expression:"colorSimilar"}],attrs:{"size":"18px","color":"rgba(237, 134, 17, 0.71)"}},on),[_vm._v("mdi-help-circle-outline")])]}}])}):_vm._e()],1),_c('div',{staticClass:"color-picker-card__action-buttons"},[_c('BaseTooltip',{attrs:{"content":_vm._f("capitalize")(_vm.$t('actions.cancel')),"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"rgba(0, 0, 0, 0.48)","text":"","rounded":"","x-small":""},on:{"click":_vm.discardChange}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}])}),_c('BaseTooltip',{attrs:{"content":_vm._f("capitalize")(_vm.$t('actions.choose')),"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.colorTaken,"color":"rgba(237, 134, 17, 0.64)","rounded":"","x-small":""},on:{"click":_vm.emitColorChange}},on),[_c('v-icon',{attrs:{"color":"rgba(256, 256, 256, 0.89)","small":""}},[_vm._v("mdi-check")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }